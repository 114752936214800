import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EVENTS, TEVENTS_PERIOD } from './useOnGoingEvent.type';

dayjs.extend(utc);
dayjs.extend(timezone);

const dayjsSeoul = (date: string) => dayjs(date).tz('Asia/Seoul');

export const EVENTS_PERIOD: TEVENTS_PERIOD = {
  [EVENTS.LOGIN_BONUS_2503]: {
    since: dayjsSeoul('2025-03-01 00:00:00'),
    until: dayjsSeoul('2025-03-31 23:59:59'),
  },
  [EVENTS.LOGIN_BONUS_2504]: {
    since: dayjsSeoul('2025-04-01 00:00:00'),
    until: dayjsSeoul('2025-04-30 23:59:59'),
  },
  [EVENTS.CHARGE_DOUBLE_2504]: {
    since: dayjsSeoul('2025-04-01 00:00:00'),
    until: dayjsSeoul('2025-04-03 23:59:59'),
  },
  [EVENTS.MOON_GREETING_240914]: {
    since: dayjsSeoul('2024-09-12 00:00:00'),
    until: dayjsSeoul('2024-09-14 23:59:59'),
  },
  [EVENTS.MOON_GREETING_240917]: {
    since: dayjsSeoul('2024-09-15 00:00:00'),
    until: dayjsSeoul('2024-09-17 23:59:59'),
  },
  [EVENTS.MOON_GREETING_240920]: {
    since: dayjsSeoul('2024-09-18 00:00:00'),
    until: dayjsSeoul('2024-09-20 23:59:59'),
  },
  [EVENTS.SKETCHUP_PROMOTION]: {
    since: dayjsSeoul('2025-03-19 10:00:00'),
    until: dayjsSeoul('2025-04-07 09:59:59'),
  },
  [EVENTS.CHRISTMAS_2024]: {
    since: dayjsSeoul('2024-12-12 00:00:00'),
    until: dayjsSeoul('2024-12-25 23:59:59'),
  },
  [EVENTS.NEW_YEAR_2025_PRE]: {
    since: dayjsSeoul('2025-01-20 00:00:00'),
    until: dayjsSeoul('2025-01-22 23:59:59'),
  },
  [EVENTS.NEW_YEAR_2025_CASH_BONUS_EVENT]: {
    since: dayjsSeoul('2025-01-23 00:00:00'),
    until: dayjsSeoul('2025-01-31 23:59:59'),
  },
  [EVENTS.NEW_YEAR_2025_PERIOD_1]: {
    since: dayjsSeoul('2025-01-23 00:00:00'),
    until: dayjsSeoul('2025-01-25 23:59:59'),
  },
  [EVENTS.NEW_YEAR_2025_PERIOD_2]: {
    since: dayjsSeoul('2025-01-26 00:00:00'),
    until: dayjsSeoul('2025-01-28 23:59:59'),
  },
  [EVENTS.NEW_YEAR_2025_PERIOD_3]: {
    since: dayjsSeoul('2025-01-29 00:00:00'),
    until: dayjsSeoul('2025-01-31 23:59:59'),
  },
};

const generateInitialEventsState = () => {
  const result = [];
  const now = dayjs().tz('Asia/Seoul');

  for (const EVENT_VALUE of Object.keys(EVENTS_PERIOD)) {
    const isBetween =
      now.isSame(EVENTS_PERIOD[EVENT_VALUE].since) ||
      now.isSame(EVENTS_PERIOD[EVENT_VALUE].until) ||
      (now.isAfter(EVENTS_PERIOD[EVENT_VALUE].since) && now.isBefore(EVENTS_PERIOD[EVENT_VALUE].until));
    isBetween && result.push(EVENT_VALUE);
  }
  return result;
};

export const INITIAL_ONGOING_EVENT_STATE = {
  onGoingEvents: generateInitialEventsState(),
};
