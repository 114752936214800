import styled from '@emotion/styled';
import { CustomText } from '@components/ui/Text';
import { withAttrs } from '@util/componentAdapter';

export const FooterContainer = styled.div`
  background: #fdfdfc;
  width: 100%;
`;

export const FooterTopWrapper = styled.div`
  border-top: 1px solid var(--color-gray-100, #e7e7e8);
  border-bottom: 1px solid var(--color-gray-100, #e7e7e8);
  padding: 14px 72px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  row-gap: 12px;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    padding: 14px 40px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 14px 24px;
  }
`;

export const FooterBottomWrapper = styled.div<{ gap?: number }>`
  padding: 40px 72px 48px 72px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || 24}px;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    padding: 40px 40px 48px 40px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 40px 24px 48px 24px;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 16px;
`;

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  background: #e7e7e8;
`;

export const SocialAndGlobalSelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 42px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 12px;
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const GlobalSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const GlobalSelectBox = styled.select`
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  & select::-ms-expand {
    display: none;
  }

  padding: 8px 32px 8px 28px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-gray-200, #d2d2d3);
  background: var(--color-white, #fff);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8 11L11.5 7H4.5L8 11Z' fill='%236D6D70'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 3px) center;
`;

export const CompanyInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  max-width: 1060px;
`;

export const CompanyInfoRow = styled.div<{ paddingTb?: '12px' }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  ${(props) =>
    props.paddingTb &&
    `
  padding-top: ${props.paddingTb};
  padding-bottom: ${props.paddingTb};
  `}
`;

export const BizInfoText = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

export const CompanyInfoText = styled(
  withAttrs(CustomText, {
    size: 11,
    weight: 500,
    lineHeight: '14px',
    color: '#6D6D70',
  }),
)`
  & > a,
  & > ${BizInfoText} {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Global = styled.div`
  display: flex;
  padding-top: 1px;
  max-height: 32px;
  max-width: 160px;
  justify-content: flex-end;
`;

export const SelectWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    // 아이콘을 네트워크 통신으로 다운로드하는 것을 막기 위해
    // dropdown-icon.svg를 하드코딩으로 박아넣음
    // utf8으로는 작동하지 않아서, fully encode해서 작성함
    background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 11L11 7H5L8 11Z" fill="%23333333"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    position: absolute;
    width: 18px;
    height: 26px;
    top: 6px;
    right: 1px;
    font-size: 8px;
    z-index: 1;
    pointer-events: none;
    color: #333;
  }
`;

export const GlobalSelect = styled.select`
  vertical-align: bottom;
  padding: 7px 28px 5px 15px;
  border: 1px solid #cdcdcd;
  margin-left: 13px;
  text-align-last: center;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  & > option {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;
