import React, { useContext, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useLocalStorage } from 'react-use';
import {
  AconLogoSkeleton,
  CashBonusLabel,
  CashIndicatorWrapper,
  GnbContainer,
  GnbIconWrapper,
  IndustryToggleButton,
  MypageMenuWrapper,
  ScrapIconWrapper,
  SearchIconWrapper,
} from '@components/_new_components/Common/layout/Header/Gnb/Gnb.styles';
import { FlexRowAlignCenter } from '@components/wrapper';
import CashIndicator from '@components/_new_components/Common/layout/Header/Gnb/CashIndicator';
import { CustomLink } from '@components/ui/Link';
import { useUserInfo } from '@hooks';
import { Skeleton } from '@components/ui/Skeleton';
import Search from '@components/_new_components/Common/layout/Header/Gnb/search/Search';
import MyPage from '@components/_new_components/Common/layout/Header/MyPage/MyPage';
import { buttonClickLog } from '@lib/mixpanel/events';
import HeaderSearchIconTablet from 'src/assets/icons/gnb/HeaderSearchIconTablet';
import HeaderSearchIconMobile from 'src/assets/icons/gnb/HeaderSearchIconMobile';
import { useOnGoingEventStore } from 'src/stores/event/useOnGoingEvent.store';
import { EVENTS } from 'src/stores/event/useOnGoingEvent.type';
import { LANGUAGE_CODE } from '../../../../../../src/constants';
import { CommonContext, UserInfoStatusEnum } from '../../../../../../src/provider';
import GnbCart from '../../../../../../src/assets/GnbCart';
import GnbUser from '../../../../../../src/assets/GnbUser';
import GnbScrap from '../../../../../../src/assets/GnbScrap';
import GnbUserFilled from '../../../../../../src/assets/GnbUserFilled';
import { PATH_MYPAGE } from '../../../../../../src/routes/paths';
import { useIndustry } from '../../../../../../src/stores/industry/useIndustryStore';
import { Industry } from '../../../../../../src/constants/industry.constants';
import { LanguageCodeEnum } from '../../../../../../src/generated/graphql';
import { useDisplaySnackbarOnReload } from '../../../../../../src/stores/layout/useLayout.store';

const AconLogoIcon = dynamic(() => import('../../../../../../src/assets/icons/Logo/AconLogo'), {
  ssr: false,
  loading: () => <AconLogoSkeleton />,
});

export default function Gnb() {
  const { i18n, t } = useTranslation();
  const { cashData, carts, cartsOnSession } = useContext(CommonContext);
  const { status } = useUserInfo();
  const [isDisplayMypageMenu, setIsDisplayMypageMenu] = useState(false);
  const { industry, reloadPageOnIndustryChange } = useIndustry();
  const { setSnackbarMessageOnReload } = useDisplaySnackbarOnReload();

  const { onGoingEvents } = useOnGoingEventStore((state) => ({ onGoingEvents: state.onGoingEvents }));
  const [cashIndicatorBadge, setCashIndicatorBadge] = useLocalStorage(`storage_${EVENTS.CHARGE_DOUBLE_2504}`, '');
  const isOnGoingCashEvent = onGoingEvents.includes(EVENTS.CHARGE_DOUBLE_2504) && i18n.language !== LANGUAGE_CODE.CN;

  const showCashBadge = isOnGoingCashEvent && cashIndicatorBadge !== EVENTS.CHARGE_DOUBLE_2504;

  const isLogin = status === UserInfoStatusEnum.authenticated;
  const isLoadingLogin = status === UserInfoStatusEnum.loading;
  const cartCount = useMemo(() => (status === UserInfoStatusEnum.authenticated ? carts : cartsOnSession)?.length, [status, carts, cartsOnSession]);

  const handleCashIndicatorClick = () => {
    buttonClickLog({ actionName: 'main cash' });
    if (showCashBadge) setCashIndicatorBadge(EVENTS.CHARGE_DOUBLE_2504);
  };

  const handleScrapClick = () => {
    buttonClickLog({ actionName: 'main scrap' });
  };

  const handleCartClick = () => {
    buttonClickLog({ actionName: 'main cart' });
  };

  const handleIndustryToggleButtonClick = () => {
    reloadPageOnIndustryChange(industry === Industry.ALL ? Industry.GAME : Industry.ALL);
    setSnackbarMessageOnReload(industry === Industry.ALL ? t('menu:gnb.toggle.gameProductsMessage') : t('menu:gnb.toggle.allProductsMessage'));
  };

  const handleSearchClick = () => {
    buttonClickLog({ actionName: 'main search' });
  };

  return (
    <GnbContainer>
      <FlexRowAlignCenter gap={20}>
        <CustomLink href={'/'} className={'gnb-logo'}>
          <AconLogoIcon isBeta={i18n.language === LANGUAGE_CODE.CN} isJapanese={i18n.language === LANGUAGE_CODE.JP} />
        </CustomLink>

        <Search />
      </FlexRowAlignCenter>
      {isLoadingLogin && <Skeleton width={'150px'} height={'14px'} />}
      {!isLoadingLogin && (
        <GnbIconWrapper>
          {isLogin && (
            <Link href={PATH_MYPAGE.depositList} onClick={handleCashIndicatorClick}>
              <CashIndicatorWrapper>
                {showCashBadge && <CashBonusLabel />}
                <CashIndicator myCash={cashData} />
              </CashIndicatorWrapper>
            </Link>
          )}
          <SearchIconWrapper>
            <CustomLink className={'cursor-pointer'} href={'/tabs/search'} display={'undefined'} onClick={handleSearchClick}>
              <HeaderSearchIconTablet />
              <HeaderSearchIconMobile />
            </CustomLink>
          </SearchIconWrapper>

          {isLogin && (
            <ScrapIconWrapper>
              <CustomLink className={'cursor-pointer gnb-scrap'} href={'/mypage/clip-book'} display={'undefined'} onClick={handleScrapClick}>
                <GnbScrap />
              </CustomLink>
            </ScrapIconWrapper>
          )}

          <CustomLink className={'cursor-pointer'} href={'/mypage/cart'} onClick={handleCartClick}>
            <GnbCart count={cartCount} />
          </CustomLink>

          {isLogin ? (
            <MypageMenuWrapper onMouseOver={() => setIsDisplayMypageMenu(true)} onMouseOut={() => setIsDisplayMypageMenu(false)}>
              <GnbUserFilled />
              {isDisplayMypageMenu && <MyPage isCoupon={false} onClose={() => setIsDisplayMypageMenu(false)} />}
            </MypageMenuWrapper>
          ) : (
            <CustomLink className={'flex items-center justify-center relative cursor-pointer'} href={`/${i18n.language}/users/login`}>
              <GnbUser />
            </CustomLink>
          )}

          <IndustryToggleButton
            language={(i18n.language as LanguageCodeEnum) === LanguageCodeEnum.Ko ? LanguageCodeEnum.Ko : LanguageCodeEnum.En}
            industry={industry}
            onClick={handleIndustryToggleButtonClick}
          />
        </GnbIconWrapper>
      )}
    </GnbContainer>
  );
}
