import React, { ReactElement, ReactNode, useEffect } from 'react';
import notifly from 'notifly-js-sdk';
import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import { appWithTranslation, TFunction, useTranslation } from 'next-i18next';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Hydrate, QueryClientProvider } from 'react-query';
import { useRouter } from 'next/router';
import { DesignSystemProvider } from 'carpenstreet-designsystem';
import { datadogRum } from '@datadog/browser-rum';
import { useLocalStorage } from 'react-use';
import { NextPage } from 'next';
import Page from '@components/Page';
import { useApollo } from '@lib/apolloClient';
import 'tailwindcss/tailwind.css';
import { SettingsProvider } from 'src/context/SettingContext';
import ThemeSettings from '@components/settings';
import { Layout } from '@components/layout/Layout';
import * as mixpanel from '@lib/mixpanel';
import { campaignParams } from '@lib/mixpanel';
import { ModalGroup } from '@components/common/Modal';
import { logEvent, useGA4PageView } from '@hooks/logger';
import { convertLangCodeForDB } from '@lib';
import { CommonProvider } from 'src/provider';
import Errors from '@components/Errors/Errors';
import { ErrorBoundary } from '@components/Errors/ErrorBoundary';
import { reactQueryClient } from '@lib/reactQuery/reactQueryClient';
import { useHistoryStore } from 'src/stores/history/useHistory.store';
import { FacebookTag, GoogleAnalyticsTag, GoogleTag, NaverCommonScriptTag, TwitterPixelJapanTag, TwitterPixelTag, VwoSmartCode } from '@components/layout/Marketing';
import { useNotiflyStore } from 'src/stores/notifly/useNotiflyStore';
import { preventAllAbuse } from '@util/security';
import { CATEGORY_CODE, IS_PROD } from '../src/constants';
import nextI18NextConfig from '../next-i18next.config.js';
import { useIndustry } from '../src/stores/industry/useIndustryStore';

ErrorBoundary.displayName = 'ErrorBoundary';

interface GlobalTranslation {
  t?: TFunction | ((arg: any) => any);
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
}

const getPageType = (urlOrigin: string) => {
  const url = urlOrigin.replace(/\/ko|\/en|\/ja|\/zh/g, '');
  if (url === '') {
    return 'main';
  } else if (url.includes('best')) {
    return 'best';
  } else if (url.includes('comet-deal')) {
    return 'comet-deal';
  } else if (url.includes('new')) {
    return 'new';
  } else if (url.includes('search')) {
    return 'search';
  } else if (url.includes('cosmic-deal')) {
    return 'cosmic-deal';
  } else if (url.includes('photoreviews')) {
    return 'photoreviews';
  } else if (url.includes(`/category/${CATEGORY_CODE.ABLER}`)) {
    return 'abler';
  } else if (url.includes('intro')) {
    return 'intro';
  } else if (url.includes('brochure')) {
    return 'brochure';
  } else if (url.includes('game')) {
    return 'game';
  } else if (url.includes('/users/join')) {
    return 'signup';
  } else if (url.includes('/users/login')) {
    return 'signin';
  } else if (url.includes('genre')) {
    return 'genre';
  }

  return null;
};

export const globalTranslation: GlobalTranslation = { t: (str) => str };

function MyApp({ Component, pageProps }: MyAppProps): React.ReactElement {
  const [authToken, setAuthToken, removeAuthToken] = useLocalStorage('acon-auth-token', '');
  const { history, setHistory } = useHistoryStore();
  const { isNotiflySDKReady, setIsNotiflySDKReady } = useNotiflyStore();

  const { initializeIndustry } = useIndustry();

  const getLayout = Component.getLayout ?? ((page) => page);

  // cookie
  const [cookies, setCookie] = useCookies();
  // 번역도구
  const { t, i18n } = useTranslation();
  globalTranslation.t = t;
  const router = useRouter();

  useEffect(() => {
    if (i18n.language) {
      const utmLastTouchParams = campaignParams();
      mixpanel.setSuperProperty({
        'Mall (language)': i18n.language === 'acon' ? 'en' : i18n.language,
        ...utmLastTouchParams,
      });
    }
  }, [i18n.language]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Send track event when new pages is loaded
      // 퍼널에서 비교 분석 용이하게 하기 위해서 event name 페이지 url에 따라 변경
      const pageType = getPageType(url);

      // [AC-467](https://carpenstreet.atlassian.net/browse/AC-467)
      // 장르 페이지에 대해서는 장르 페이지 컴포넌트 내에서 처리한다.
      if (pageType === 'genre') {
        return;
      }

      mixpanel.event('Page view', {
        url,
      });
      if (pageType) logEvent(`Page view_${pageType}`, { url });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (typeof window !== 'undefined') initializeIndustry();
    if (history.length === 0) {
      setHistory(`${i18n.language}${router.asPath}`);
    }
    const handleRouteChange = (url: string) => setHistory(url);

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // initialize apollo client
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const apolloClient = useApollo(
    pageProps.initialApolloState,
    {
      authToken,
      setAuthToken,
      removeAuthToken,
    },
    i18n.language,
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (!IS_PROD) {
      const tailwindCss = Array.from(document.head.querySelectorAll('style')).find((e) => e.innerHTML.includes('tailwindcss'));
      document.head.prepend(tailwindCss);
    }

    setTimeout(() => {
      preventAllAbuse();
    }, 0);

    if (IS_PROD) {
      datadogRum.init({
        applicationId: '72a2102c-0ed0-4f27-979a-50589b2f8328',
        clientToken: 'pubf54da44c5172edad20fd3df79025910e',
        site: 'datadoghq.com',
        service: 'acon3d',
        env: 'production',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 10,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        silentMultipleInit: true,
      });
      datadogRum.startSessionReplayRecording();
    }

    const url = window.location?.href?.replace(window.location?.origin, '');
    if (!url) {
      return;
    }
    let mixpanelEventName = 'Page view';
    const pageType = getPageType(url);

    if (url.includes('/order/ablur')) mixpanelEventName = 'Page view in ABLUR 2.0';

    mixpanel.event(mixpanelEventName, { url });
    if (pageType) logEvent(`Page view_${pageType}`, { url });
  }, []);

  useEffect(() => {
    // SSR
    if (typeof window === 'undefined') {
      return;
    }

    /**
     * URL에 언어 설정이 포함되어 있을 경우 해당 언어로 쿠키를 설정합니다.
     * v2 언어 설정을 위해 NEXT_LOCALE 쿠키를 설정하고
     * 기존 고도몰과 연동을 위해 lang 쿠키도 같이 설정합니다.
     */
    if (router.locale !== 'acon') {
      // 쿠키 만료일자
      const expires = new Date();
      // 쿠키 만료일자를 내년으로 설정
      expires.setFullYear(expires.getFullYear() + 1);

      // 현재 접근한 URL로 NEXT_LOCALE 쿠키를 설정합니다.
      if (i18n.language) {
        setCookie('NEXT_LOCALE', i18n.language, { path: '/', expires });
      }

      // 고도몰 언어 설정에 맞게 언어 코드 변환
      const parseLang = convertLangCodeForDB(i18n.language);

      // 현재 설정된 쿠키가 현재 URL로 설정된 언어와 다를 경우 URL 기준으로 쿠키 재설정
      // 고도몰과 호환을 위해 설정
      if (parseLang && cookies.lang !== parseLang) {
        // 쿠키 설정
        setCookie('lang', parseLang, { path: '/', expires, domain: '.acon3d.com' });
      }
    }
  }, [i18n.language]);

  useGA4PageView();

  const findParent = (element: HTMLElement, finder: (el: HTMLElement) => boolean): HTMLElement => {
    if (!element) {
      return null;
    }
    if (!finder(element)) {
      return findParent(element.parentElement, finder);
    }
    return element;
  };

  useEffect(() => {
    async function initializeNotifly() {
      await notifly.initialize({
        projectId: process.env.NEXT_PUBLIC_NOTIFLY_PROJECT_ID,
        username: process.env.NEXT_PUBLIC_NOTIFLY_PROJECT_USERNAME,
        password: process.env.NEXT_PUBLIC_NOTIFLY_PROJECT_PASSWORD,
      });
      setIsNotiflySDKReady(true);
    }
    if (typeof window !== 'undefined') {
      initializeNotifly();
      const handleClickMixpanel = (e) => {
        const mixpanelDatasetHolder = findParent(e.target as HTMLElement, (el) => el?.dataset?.mixpanelAction && el.dataset.mixpanelAction === 'click');
        if (mixpanelDatasetHolder) {
          const event = Object.entries(mixpanelDatasetHolder.dataset)
            .map(([k, v]) => {
              const key = k.replace(/^mixpanel/, '');
              return [key.charAt(0).toLowerCase() + key.slice(1), v];
            })
            .filter(([k]) => k !== 'action' && k !== 'collectOnce')
            .reduce((obj, [k, v]) => ({ ...obj, [`${k}`]: v }), {}) as {
            evt: string;
            [key: string]: string;
          };
          const eventNames = event.evt.split(', ');
          delete event.evt;

          eventNames.forEach((eventName) => mixpanel.event(eventName, event));

          // 한 번만 수집하게 acion attribute를 제거
          if (mixpanelDatasetHolder.dataset?.mixpanelCollectOnce && mixpanelDatasetHolder.dataset.mixpanelCollectOnce === 'true') {
            mixpanelDatasetHolder.removeAttribute('data-mixpanel-action');
          }
        }
      };
      window.addEventListener('click', handleClickMixpanel);
      return () => {
        window.removeEventListener('click', handleClickMixpanel);
      };
    }
  }, []);

  return (
    <DesignSystemProvider>
      <ApolloProvider client={apolloClient}>
        {IS_PROD && (
          <>
            <GoogleTag />
            <FacebookTag />
            <TwitterPixelTag />
            <TwitterPixelJapanTag />
            <GoogleAnalyticsTag />
            <NaverCommonScriptTag />
            <VwoSmartCode />
          </>
        )}
        <QueryClientProvider client={reactQueryClient}>
          <CookiesProvider>
            <CommonProvider
              tokenStorageHook={{
                authToken,
                setAuthToken,
                removeAuthToken,
              }}
              locale={pageProps?._nextI18Next?.initialLocale}
            >
              <SettingsProvider themeMode={'toon'}>
                <ThemeSettings>
                  <ErrorBoundary
                    fallback={(props) => (
                      <Layout>
                        <Page>
                          <Errors {...props} />
                        </Page>
                      </Layout>
                    )}
                  >
                    <Hydrate state={pageProps.dehydratedState}>
                      <ModalGroup />
                      {getLayout(<Component {...pageProps} />)}
                    </Hydrate>
                  </ErrorBoundary>
                </ThemeSettings>
              </SettingsProvider>
            </CommonProvider>
          </CookiesProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </DesignSystemProvider>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
