import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { CustomText } from '@components/ui/Text';
import {
  BizInfoText,
  CompanyInfoRow,
  CompanyInfoText,
  CompanyInfoWrapper,
  Divider,
  FooterBottomWrapper,
  FooterContainer,
  FooterTopWrapper,
  GlobalSelectBox,
  GlobalSelectWrapper,
  NavigationWrapper,
  SocialAndGlobalSelectWrapper,
  SocialWrapper,
} from '@components/_new_components/Common/layout/Footer/Footer.styles';
import { useAdultCertification } from '@hooks/useAdultCertifiation';
import { LanguageCodeEnum } from '../../../../../src/generated/graphql';
import { PATH_FAQ, PATH_PAGE } from '../../../../../src/routes/paths';
import KakaoGrayIcon from '../../../../../src/assets/icons/footer/KakaoGrayIcon';
import InstaGrayIcon from '../../../../../src/assets/icons/footer/InstaGrayIcon';
import TwitterGrayIcon from '../../../../../src/assets/icons/footer/TwitterGrayIcon';
import FacebookGrayIcon from '../../../../../src/assets/icons/footer/FacebookGrayIcon';
import GlobalIcon from '../../../../../src/assets/icons/footer/GlobalIcon';
import { LANGUAGE_CODE } from '../../../../../src/constants';
import AconGrayLogo from '../../../../../src/assets/icons/Logo/AconGrayLogo';
import WeiboGrayIcon from '../../../../../src/assets/icons/footer/WeiboGrayIcon';

export default function Footer() {
  const router = useRouter();
  const { t, i18n } = useTranslation(['footer']);
  const { checkRefreshAdultCertification } = useAdultCertification();

  const changeLanguage = async (e) => {
    const locale = e.target.value;
    await checkRefreshAdultCertification(locale);
    router.replace(router.asPath, router.asPath, { locale: locale });
  };

  const handleBizInfoClick = () => {
    // 사업자 등록번호 코드
    const code = 3648701374;
    // window open
    const win = window.open(
      `http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=${code}`,
      'communicationViewPopup',
      'width=750,height=700,resizable=no,scrollbars=no',
    );
    win.focus();
    // window 반환
    return win;
  };

  return (
    <FooterContainer>
      <FooterTopWrapper>
        <NavigationWrapper>
          {/** 이용약관 */}
          <Link href="/policy/terms-of-use" target={'_blank'}>
            <CustomText weight={500} lineHeight={'100%'} color={'#6d6d70'}>
              {t('footer:tos')}
            </CustomText>
          </Link>
          <Divider />
          {/** 개인정보처리방침 */}
          <Link href="/policy/privacy" target={'_blank'}>
            <CustomText weight={700} lineHeight={'100%'} color={'#313135'}>
              {t('footer:policy')}
            </CustomText>
          </Link>
          <Divider />
          {/** EULA */}
          <Link href={'/policy/eula'} target={'_blank'}>
            <CustomText weight={500} lineHeight={'100%'} color={'#6d6d70'}>
              {t('footer:eula')}
            </CustomText>
          </Link>
          <Divider />
          {/** 에이콘 소개 */}
          <Link href={PATH_PAGE.intro}>
            <CustomText weight={700} lineHeight={'100%'} color={'#313135'}>
              {t('footer:aboutAcon')}
            </CustomText>
          </Link>
          {/** 입점신청 */}
          {i18n.language !== 'zh' && (
            <>
              <Divider />
              <Link href={PATH_PAGE.intro3dCreator}>
                <CustomText weight={700} lineHeight={'100%'} color={'#313135'}>
                  {t('footer:intro3dCreator')}
                </CustomText>
              </Link>
            </>
          )}
          <Divider />
          {/** 고객센터 */}
          <Link href={PATH_FAQ.root}>
            <CustomText weight={500} lineHeight={'100%'} color={'#6d6d70'}>
              {t('footer:cs')}
            </CustomText>
          </Link>
          {/** 채용정보 및 공지사항 */}
          {i18n.language === LanguageCodeEnum.Ko && (
            <>
              <Divider />
              <Link href={'https://www.notion.so/acon3d/8ea4db50bd6b472dab17b94316e850e3'} target={'_blank'}>
                <CustomText weight={500} lineHeight={'100%'} color={'#6d6d70'}>
                  채용정보
                </CustomText>
              </Link>
              <Divider />
              <Link href={'https://acon3d.notion.site/05880402c6764fc6ae3f975bfd0f9cd7?v=52dc73ed1989485b903cac5e75d20fa3'} target={'_blank'}>
                <CustomText weight={500} lineHeight={'100%'} color={'#6d6d70'}>
                  공지사항
                </CustomText>
              </Link>
            </>
          )}
          {/* 일문몰 특정 상거래법에 의거한 표시 */}
          {i18n.language === LanguageCodeEnum.Ja && (
            <>
              <Divider />
              <Link href={'/ja/policy/company-info'}>
                <CustomText weight={500} lineHeight={'100%'} color={'#6d6d70'}>
                  {t('footer:footerInfoForJapan')}
                </CustomText>
              </Link>
            </>
          )}
        </NavigationWrapper>
        <SocialAndGlobalSelectWrapper>
          <SocialWrapper>
            {i18n.language === LanguageCodeEnum.Ko && (
              <a href="https://pf.kakao.com/_TDFsT" target="_blank" rel="noreferrer">
                <KakaoGrayIcon />
              </a>
            )}
            {i18n.language === LanguageCodeEnum.Zh && (
              <a href="https://www.weibo.com/acon3dofficial" target="_blank" rel="noreferrer">
                <WeiboGrayIcon />
              </a>
            )}
            <a href={t('footer:instaLink')} target="_blank" rel="noreferrer">
              <InstaGrayIcon />
            </a>
            <a href={t('footer:twitterLink')} target="_blank" rel="noreferrer">
              <TwitterGrayIcon />
            </a>
            <a href={t('footer:facebookLink')} target="_blank" rel="noreferrer">
              <FacebookGrayIcon />
            </a>
          </SocialWrapper>

          <GlobalSelectWrapper>
            <GlobalIcon />
            <GlobalSelectBox value={i18n.language} onChange={changeLanguage}>
              <option value={LANGUAGE_CODE.KO}>한국어</option>
              <option value={LANGUAGE_CODE.EN}>English</option>
              <option value={LANGUAGE_CODE.JP}>日本語</option>
            </GlobalSelectBox>
          </GlobalSelectWrapper>
        </SocialAndGlobalSelectWrapper>
      </FooterTopWrapper>
      {i18n.language === LANGUAGE_CODE.JP ? (
        <FooterBottomWrapper gap={16}>
          <AconGrayLogo isJapanese={true} />
          <CompanyInfoWrapper>
            <CompanyInfoRow>
              <CompanyInfoText style={{ color: '#bcbcbc' }}>copyright (c) www.acon3d.com all rights reserved.</CompanyInfoText>
            </CompanyInfoRow>
          </CompanyInfoWrapper>
        </FooterBottomWrapper>
      ) : (
        <FooterBottomWrapper>
          <AconGrayLogo isJapanese={false} />
          <CompanyInfoWrapper>
            {i18n.language === LanguageCodeEnum.Ko && (
              <>
                <CompanyInfoRow>
                  <CompanyInfoText>{t('footer:copyright.company')}</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.representative')}</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.address')}</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.morn')}</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.brn')}</CompanyInfoText>
                  <CompanyInfoText className={'cursor-pointer'} onClick={handleBizInfoClick}>
                    <BizInfoText>{t('footer:copyright.bic')}</BizInfoText>
                  </CompanyInfoText>
                </CompanyInfoRow>
                <CompanyInfoRow>
                  <CompanyInfoText>{t('footer:copyright.pim')}</CompanyInfoText>
                  <CompanyInfoText>번호(발신전용): 02-878-1201</CompanyInfoText>
                  <CompanyInfoText>이메일: cs@acon3d.com</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.hosting')}</CompanyInfoText>
                </CompanyInfoRow>
                <CompanyInfoRow paddingTb="12px">
                  <CompanyInfoText>{t('footer:responsibility')}</CompanyInfoText>
                </CompanyInfoRow>
                <CompanyInfoRow>
                  <CompanyInfoText style={{ color: '#bcbcbc' }}>copyright (c) www.acon3d.com all rights reserved.</CompanyInfoText>
                </CompanyInfoRow>
              </>
            )}

            {i18n.language !== LanguageCodeEnum.Ko && (
              <>
                <CompanyInfoRow>
                  <CompanyInfoText>{t('footer:copyright.company')}</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.representative')}</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.address')}</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.morn')}</CompanyInfoText>
                </CompanyInfoRow>
                <CompanyInfoRow>
                  <CompanyInfoText>
                    {t('footer:copyright.brn')}
                    <BizInfoText onClick={handleBizInfoClick}>{t('footer:copyright.bic')}</BizInfoText>
                  </CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.pim')}</CompanyInfoText>
                  {i18n.language === LANGUAGE_CODE.JP && <CompanyInfoText>お問い合わせ先 : +82 10-5763-5314</CompanyInfoText>}
                  <CompanyInfoText>Contact: cs@acon3d.com</CompanyInfoText>
                  <CompanyInfoText>{t('footer:copyright.hosting')}</CompanyInfoText>
                </CompanyInfoRow>
                <CompanyInfoRow paddingTb="12px">
                  <CompanyInfoText>{t('footer:responsibility')}</CompanyInfoText>
                </CompanyInfoRow>
                <CompanyInfoRow>
                  <CompanyInfoText style={{ color: '#bcbcbc' }}>copyright (c) www.acon3d.com all rights reserved.</CompanyInfoText>
                </CompanyInfoRow>
              </>
            )}
          </CompanyInfoWrapper>
        </FooterBottomWrapper>
      )}
    </FooterContainer>
  );
}
