import React from 'react';
import styled from '@emotion/styled';

export const ErrorsContainer = styled.div<React.CSSProperties>`
  padding-block: 100px;
  width: 100%;
  height: 772px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  flex-direction: column;
  gap: 36px;
`;
export const ErrorsImage = styled.img<React.CSSProperties>``;

export const ErrorsDescription = styled.div<React.CSSProperties>`
  white-space: pre;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42;
  color: #313135;
`;

export const ErrorsControlButtons = styled.div<React.CSSProperties>`
  gap: 12px;
  display: flex;
  button {
    padding: 0;
    width: 120px;
    height: 40px;
    border-radius: 8px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    box-sizing: border-box;
    &.home {
      font-size: 14px;
      line-height: 20px;
      color: #313135;
      border: 1px solid rgba(188, 188, 190, 0.5);
    }
    &.back {
      background-color: #313135;
      color: #ffffff;
    }
  }
`;
