const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: 'acon',
    // 중국어(zh)는 더이상 사용되지 않습니다. 하지만 이전에 중문몰로 링크되는 uri가 만들어져 있으므로(ex: https://acon3d.com/zh/genre)
    // 만약 아래에서 'zh'를 제거한다면 해당 링크를 타고 들어왔을 때에는 https://acon3d.com/en/zh/genre로 잘못 리다이렉트 되어버립니다.
    // 따라서 여기서는 zh를 제거하지 않고 mddleware.ts에서 중문몰일 경우 en으로 리다이렉트 시켜주는 작업을 해줍니다.
    locales: ['acon', 'en', 'ko', 'zh', 'ja'],
  },
  localePath: typeof window === 'undefined' ? path.resolve('./public/locales') : '/locales',
};
